import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import GatsbyLink from "../GatsbyLink";
import PostListItem from "../Blog/PostListItem";
import "./RelatedPosts.scss";

const PostComponent = ({
  data: {
    allWordpressPost: { edges: posts },
    wordpressWpSettings: { date_format: dateFormat },
  },
  action,
}) => {
  return (
    <section className="related-posts">
      <div className="related-posts-wrap">
        <h2>
          <GatsbyLink to={action?.url} className="button--dark">
            {action?.title}
          </GatsbyLink>
        </h2>

        <ul className="related-post-list">
          {Object.keys(posts).length > 0 &&
            posts?.map(
              (post, index) =>
                post.node.slug !== "gatsby-demo" && (
                  <li>
                    <PostListItem
                      key={index}
                      data={post.node}
                      dateFormat={dateFormat}
                    />
                  </li>
                )
            )}
        </ul>
      </div>
    </section>
  );
};

const RelatedPosts = (props) => {
  const postType = props.contentType ? props.contentType : "post";
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
            date_format
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          }
          categoryFilter: allWordpressPost {
            nodes {
              categories {
                wordpress_id
                id
                name
                slug
                id
              }
            }
          }
          allWordpressPost(sort: { fields: date, order: DESC }, limit: 3) {
            edges {
              node {
                ...PostListFields
              }
            }
          }
        }
      `}
      render={(data) => <PostComponent {...props} data={data} />}
    />
  );
};

export default RelatedPosts;
