import React from "react";
import "./PageHeader.scss";
import "../../../theme/PageHeader.scss";
import { ImagePass } from "../Image/ImagePass";

export const PageHeader = (props) => {
  const {
    headerTitle,
    headerSubTitle,
    headerBackgroundImage,
    location,
    compact = true,
  } = props;

  return (
    <section className="page-header compact for-post-pages">
      <div className="hero">
        {headerBackgroundImage ? (
          <ImagePass
            src={
              headerBackgroundImage.source_url
                ? headerBackgroundImage.source_url
                : headerBackgroundImage
            }
            className="background"
          />
        ) : (
          <div className="placeholder" />
        )}
      </div>
      <div className="breadcrumb-component">
        <a href="#" className="breadcrumb-link button--dark">
          {headerTitle}
        </a>
      </div>
    </section>
  );
};
