import React from "react";
import { graphql } from "gatsby";
import GatsbyLink from "../components/GatsbyLink";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import { PageHeader } from "../components/PageHeader";
import RelatedPosts from "../components/Related/RelatedPosts";
import { RenderShortcode } from "../components/RenderShortcode";
import { ImagePass } from "../components/Image/ImagePass";
import "./post.scss";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";
import { dateI18n } from "@wordpress/date";

export const BlogPostTemplate = ({
  content,
  categories,
  title,
  date,
  // author: { name: authorName },
  blogSlug,
  showAuthor,
  gridGallery = {},
  subheading,
  workAuthor,
}) => {
  console.log("blog post subheading", subheading);
  return (
    <section className="single-post">
      <div className="single-post-wrap">
        <div className="col">
          <h2>{title}</h2>
          <p className="eyebrow">
            {subheading}
            {/* {workAuthor && <span className="author">By {workAuthor}</span>} */}
          </p>
        </div>
        <div className="col">
          <RenderShortcode content={content} />
        </div>
      </div>
      {gridGallery && (
        <div className="grid-gallery">
          {Object.keys(gridGallery).length > 0 &&
            gridGallery?.map((gridItem, index) => {
              return (
                <div className="grid-gallery__item" key={index}>
                  <div className="image-container">
                    <ImagePass src={gridItem?.image} />
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <div className="more-link">
        <GatsbyLink to="/news-and-media/" className="button--dark">
          More news
        </GatsbyLink>
      </div>
    </section>
  );
};

const BlogPost = (props) => {
  const { data, location } = props;
  const { wordpressPost: post, site, siteSettings, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  if (!post) return null;
  const {
    title,
    content,
    excerpt,
    featured_media,
    yoast,
    categories,
    date,
    author,
    acf,
  } = post;

  const { blogSlug, date_format } = wordpressWpSettings;
  const dateFormatted = date && date_format && dateI18n(date_format, date);
  const { subheading, workAuthor, gridGallery } = acf;
  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={
          featured_media &&
          featured_media.source_url &&
          featured_media.source_url
        }
        location={location}
      />
      <PageHeader
        headerTitle={title}
        headerSubTitle={dateFormatted}
        headerBackgroundImage={featured_media}
        location={location}
      />
      <BlogPostTemplate
        content={content}
        categories={categories}
        title={title}
        date={dateFormatted}
        author={author}
        blogSlug={blogSlug}
        showAuthor={siteSettings.options.showAuthor}
        gridGallery={gridGallery}
        subheading={subheading}
        workAuthor={workAuthor}
      />
    </>
  );
};

export default Previewable(BlogPost, "post");

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date
    title
  }
  query BlogPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      path
      content
      excerpt
      wordpress_id
      date
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      categories {
        name
        slug
        path
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        # opengraph_image {
        #   source_url
        # }
        # twitter_image {
        #   source_url
        # }
      }
      acf {
        subheading
        workAuthor
        gridGallery {
          image {
            source_url
          }
        }
      }
    }
  }
`;
